<div class="position-relative">
  <ng-template #displayWebsite></ng-template>
  <div *ngIf="showEditor && !multiTabEditor" class="editor-changes" [style.height]="!collpaseEditor ? '50px' : ''"
    [ngClass]="{'rightDirection': editorDirection === 'RIGHT', 'leftDirection': editorDirection === 'LEFT'}">
    <div [style.visibility]="showEditor ? 'visible' : 'hidden'" class="open-editor">
      <div class="top-section">
        <div class="component-name">
          {{ sectionName }}
        </div>
        <div class="close-direction-btns">
          <div class="direction-btn" (click)="changeEditorDirection(editorDirection)" *ngIf="screenWidth > 475">
            <img src="../../../../../../../../assets/images/right-drag.svg" alt=""
              [style.transform]="editorDirection == 'LEFT' ? 'rotate(180deg)' : ''">
          </div>
          <div class="close-btn" (click)="dismiss()">
            Done
          </div>
        </div>
      </div>
      <app-floating-editor [templatePage]="templatePage" [currentPageIndex]="currentPageIndex"></app-floating-editor>
    </div>
  </div>
  <div *ngIf="multiTabEditor" class="editor-changes" [style.height]="!collpaseEditor ? '50px' : ''"
    [ngClass]="{'rightDirection': editorDirection === 'RIGHT', 'leftDirection': editorDirection === 'LEFT'}">
    <div [style.visibility]="multiTabEditor ? 'visible' : 'hidden'" class="open-editor">
      <div [style.visibility]="multiTabEditor ? 'visible' : 'hidden'" class="open-editor">
        <div class="top-section" *ngIf="!singleBannerEditor">
          <div class="component-name">
            {{ sectionData?.sectionName | titlecase }}
          </div>
          <div class="close-direction-btns">
            <div class="direction-btn" (click)="changeEditorDirection(editorDirection)" *ngIf="screenWidth > 475">
              <img src="../../../../../../../../assets/images/right-drag.svg" alt=""
                [style.transform]="editorDirection == 'LEFT' ? 'rotate(180deg)' : ''">
            </div>
            <div class="close-btn" (click)="dismiss()">
              done
            </div>
          </div>
        </div>
        <div class="top-section" *ngIf="singleBannerEditor">
          <div class="component-name">
            {{ toOrdinalWord(editorTitle) | titlecase }}Banner Item
          </div>
          <div class="close-direction-btns">
            <div class="direction-btn" (click)="changeEditorDirection(editorDirection)" *ngIf="screenWidth > 475">
              <img src="../../../../../../../../assets/images/right-drag.svg" alt=""
                [style.transform]="editorDirection == 'LEFT' ? 'rotate(180deg)' : ''">
            </div>
            <div class="close-btn" (click)="singleBannerEditor = false">
              Done
            </div>
          </div>
        </div>
        <div *ngIf="!singleBannerEditor">
          <app-multi-item-carousel [data]="sectionData"
            (editSingleBanner)="gettingSingleBannerButton($event)"
            (addNewItem)="addingNewBanner($event)"></app-multi-item-carousel>
        </div>
        <div *ngIf="singleBannerEditor">
          <app-floating-editor [templatePage]="templatePage" [currentPageIndex]="currentPageIndex"></app-floating-editor>
        </div>
      </div>
    </div>
  </div>
  <div class="delete-popup">
    <div class="modal" id="deletepopup" tabindex="-1" aria-labelledby="deletepopupLabel" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered" style="z-index:1001;">
        <div class="modal-content">
          <div class="top-section-modal">
            <img src="../../../../../../assets/images/invoice/delete-invoice.svg" alt class="delete-img">
            <div class="delete-conformation">
              Are you sure to delete the component?
            </div>
          </div>
          <div class="button-section">
            <div class="left-side">
              <button type="button" class="cancel-button" data-bs-dismiss="modal"
                (click)="cancelSection()">Cancel</button>
            </div>
            <div class="right-side">
              <button type="button" class="delete-button" data-bs-dismiss="modal"
                (click)="DeleteSection()">Delete</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>