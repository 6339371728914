import { Component } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute } from '@angular/router';
import { InvoiceServiceService } from 'src/app/master-config-components/micro-apps/invoice/service/invoice-service.service';
import { DbService } from 'src/app/services/db-service/db.service';
import { StorageKeys } from 'src/app/shared-module-files/simpo.constant';
import { GlobalInvoiceDownloadComponent } from '../global-invoice-download/global-invoice-download.component';

@Component({
  selector: 'app-view-invoice-receipt',
  templateUrl: './view-invoice-receipt.component.html',
  styleUrls: ['./view-invoice-receipt.component.scss']
})
export class ViewInvoiceReceiptComponent {

  constructor(
    private _route: ActivatedRoute,
    private snackbar: MatSnackBar,
    public invoiceService: InvoiceServiceService,
    private _dbService: DbService,
  ) {

  }
  businessDetails: any;
  businessName: any;
  invoiceData: any;
  invoiceId: any
  ngOnInit() {

    this.businessDetails = window.localStorage.getItem('bDetails');
    if (this.businessDetails) {
      this.businessDetails = JSON.parse(this.businessDetails);
    }
    console.log(this.businessDetails);
    this.businessName = localStorage.getItem(StorageKeys.BUSINESS_NAME);
    // 
    this._route.queryParams.subscribe(
      (params: any) => {
        // this.invoiceId = params.invoiceId
        // setTimeout(() =>
        if (params.invoiceId) {
          this.type = 'INVOICE'
          this.getInvoiceList(params.invoiceId);
        }
        if (params.quotationId) {
          this.type = 'QUOTATION'
          this.getInvoiceList(params.quotationId)
        }
        if (params.creditNoteId) {
          this.type = 'CREDIT_NOTE'
          this.getInvoiceList(params.creditNoteId)
        }
        if(params.purchaseOrderId){
          this.type = 'PURCHASE_ORDER'
          this.getInvoiceList(params.purchaseOrderId)
        }
        if(params.debitNoteId){
          this.type = 'DEBIT_NOTE'
          this.getInvoiceList(params.debitNoteId)
        }
        if(params.deliveryChallanId){
          this.type = 'DELIVERY_CHALLAN'
          this.getInvoiceList(params.deliveryChallanId)
        }
        if(params.purchaseId){
          this.type = 'PURCHASE'
          this.getInvoiceList(params.purchaseId)
        }

      }
    )
  }
  type: string = ""

  getInvoiceList(id) {
    this.invoiceService.v2getInvoiceById(id, this.type).subscribe(
      {
        next: (value: any) => {
          this.invoiceData = value.data;
        }
      }
    )
  }
  downloadPDF() {
    let dialog = this.invoiceService.openSocialBrand(
      GlobalInvoiceDownloadComponent,
      '100%',
      '100%',
      { data: this.invoiceData }
    )
  }

}
