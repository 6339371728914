import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CrmServiceService } from '../../../../service/crm-service.service';
import { DbService } from 'src/app/services/db-service/db.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { StaffServiceService } from 'src/app/master-config-components/micro-apps/staff/service/staff-service.service';

@Component({
  selector: 'app-add-meeting-participants',
  templateUrl: './add-meeting-participants.component.html',
  styleUrls: ['./add-meeting-participants.component.scss','./mq-add-meeting-participants.component.scss']
})
export class AddMeetingParticipantsComponent {
  options: any;
  constructor(
    public dialogRef: MatDialogRef<AddMeetingParticipantsComponent>,
    private crmService:CrmServiceService,
    @Inject(MAT_DIALOG_DATA) public data:any,
    private dbService:DbService,
    private snackbar:MatSnackBar,
    private staffService: StaffServiceService,
  ){
   
    if (data.participantsList) {
      (data.participantsList || []).forEach((lead: any) => {
        this.selectedParticpants[lead.leadId] = lead;
      })
    }

    if (data.emailData) {
      this.particpantsMails = data.emailData.join(', ');
    }

    this.getParticpantList()
  }

  cancel(){
    this.dialogRef.close();
  }

 
  participantType = "STUDENT"
  mailList : any= []
  type = [
    {value : "STUDENT", viewValue : "Student"},
    {value : "STAFF", viewValue : "Staff"}
  ]
  pageNo = 0;
  size=100;
  
  searchText: string = '';
  filteredParticipants: any[] = []; // Filtered participants for the search dropdown
  selectedParticpants: { [key: string]: boolean } = {}; 
  transformedOptions: { id: string; fieldValue: string }[] = []; //
  

  staffList: any = [];
 
  // getParticpantList() {
  //   if (this.participantType === 'STAFF') {
  //     this.getStaffListing();
  //   } else if (this.participantType === 'STUDENT') {
  //     this.getStudentList();
  //   }
  // }
  
  // Filtered search logic
  // filterParticipantList() {
  //   this.displayedParticipants = this.allParticipants.filter(participant =>
  //     participant.name.toLowerCase().includes(this.searchText.toLowerCase())
  //   );
  // }
  
  // Get staff list
  getParticpantList() {
    if (this.participantType === 'STAFF') {
      this.getStaffListing();
    } else if (this.participantType === 'STUDENT') {
      this.getStudentList();
    }
  }
  
  getStaffListing() {
    this.staffService.getStaffListing(this.dbService.getBusinessId(), this.pageNo, this.size, 'ACTIVE').subscribe({
      next: (res: any) => {
        console.log(res)
        this.mailList = res.data.data.map((staff: any) => ({
          leadId: staff.id,
          leadName: staff.name,
          leadMail: staff.contact.email || 'N/A',
          companyName: staff.company || 'N/A',
        }));
        this.filteredParticipants = [...this.mailList];
      },
      error: (err: any) => {
        this.snackbar.open(`${err.error.message}`, 'Close', { duration: 1500 });
      },
    });
  }
  
  getStudentList() {
    this.crmService.searchStudentList(this.dbService.getBusinessId(), this.searchText).subscribe({
      next: (res: any) => {
        console.log(res)
        this.mailList = res.data
          .map((student: any) => {
            const field = student.fieldData.find((f: any) => f.type === 'STUDENT_NAME')
            return field
              ? {
                  leadId: student.id,
                  leadName: field.fieldValue,
                  leadMail: student.fieldData.find((f: any) => f.type === 'CONTACT_EMAIL')?.fieldValue || 'N/A',                  // companyName: student.company || 'N/A',
                }
              : null;
          })
          .filter((participant): participant is any => participant !== null);
  
        this.filteredParticipants = [...this.mailList];
        console.log(this.mailList)
      },
      error: (err: any) => {
        this.snackbar.open(`${err.error.message}`, 'Close', { duration: 1500 });
      },
    });
  }
  
  filterParticipants() {
    const search = this.searchText.toLowerCase();
    this.filteredParticipants = this.mailList.filter((participant: any) =>
      participant.leadName.toLowerCase().includes(search)
    );
  }
  selectedContact(arg0: string | null, selectedContact: any) {
    throw new Error('Method not implemented.');
  }

  // getParticpantList(){
  //   this.crmService.getLeadApis(this.dbService.getBusinessId(),this.pageNo,this.size,this.participantType).subscribe({
  //     next: (res:any)=>{

  //       this.mailList = (res.data?.data ?? []).map((lead: any) => {
  //         return { 
  //           leadId: lead.id,
  //           leadName: lead.personalInfo?.name ?? 'No Name',
  //           leadMail: lead.personalInfo?.email ?? '',
  //           companyName: lead.company?.name ?? ''
  //         }
  //       });
  //     },
  //     error: (err) => {
  //       this.snackbar.open(
  //         `${err.error.message}`,
  //         'Close',
  //         {
  //           duration:1500
  //         }
  //       )
  //     }
  //   })
  // }

  addItem(event:any,element:any){
    if(event.checked){
      this.selectedParticpants[element.leadId] = element;
    } else{
      delete this.selectedParticpants[element.leadId];
    }
  }

  addParticipants(){
    if (JSON.stringify(this.selectedParticpants) === '{}' && !this.particpantsMails) {
      this.crmService.openSnack("Please select atleast one participant.", "Ok");
      return;
    };

    let emailData: string[] = [];

    if(this.particpantsMails) {
      const emailRegex = new RegExp('[a-z0-9]+@[a-z]+\.[a-z]{2,3}');

      emailData = this.particpantsMails.split(",");

      for(let email of emailData) {
        if (!emailRegex.test(email)) {
          this.snackbar.open("Any of the email id you have entered is invalid.", "Ok", {
            duration: 1500
          });

          setTimeout(() => {
            this.snackbar.open("Hint: Please check if email ids are properly separated by commas or not", "Ok", {
              duration: 1500
            });
          }, 1500)
          return;
        }

        email = email.trim();
      }
    }

    if(this.data.type === 'Create') {
      this.dialogRef.close({participantList : Object.values(this.selectedParticpants)  , emailData: emailData, close:'create-success'})
    }

    if(this.data.type === 'Participants') {
      let payload:any = {
        participants: Object.values(this.selectedParticpants),
        email: emailData
      }
      
      this.crmService.addParticipantsByMeetId(this.data.meetingId, payload).subscribe({
        next:(res:any) => {
          this.dialogRef.close({participantList : res.data.participantsList , data : res.data.emailData, close : 'success' })
        },
        error:(err:any)=>{
          this.snackbar.open(
            `${err.error.message}`,
            'Close',
            {
              duration:1500
            }
          )
          this.dialogRef.close({close : 'fail'})
        }
      })
    }
  }


  particpantsMails: string = '';
  searchContactList() {
    if(!this.searchText.length) {
      this.getParticpantList();
    } else if(this.searchText.length >= 2) {
      this.crmService.searchLeadsCustomers(this.dbService.getBusinessId(),0,100,this.participantType,this.searchText).subscribe(
        (res:any)=>{
          this.mailList = (res.data?.data ?? []).map((lead: any) => {
            return { 
              leadId: lead.id,
              leadName: lead.personalInfo?.name ?? 'No Name',
              leadMail: lead.personalInfo?.email ?? '',
              companyName: lead.company?.name ?? ''
            }
          });
        }
      )
    }
  }
}
  