import { EventEmitter, HostListener, Injectable } from '@angular/core';
import { environment } from './../../../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { Observable, catchError, map } from 'rxjs';
import { PageTheme } from './model/theme.modal';

@Injectable({
  providedIn: 'root'
})
export class WebisiteService {
  constructor(
    private http: HttpClient,
    private snackbar: MatSnackBar,
    private _bottomSheet: MatBottomSheet
  ) {
    this.getScreenSize();
  }

  screenWidth:any;
  @HostListener("window: resize", ["$event"])
  getScreenSize(){
    this.screenWidth = window.innerWidth;
  }

  updateWebsiteStatus(data:any){
    return this.http.put(
      environment.baseUrl+`business/website/status?businessId=${data.bId}&status=${data.status}`,data
    )
  }
  updateWebsiteUrl(data:any){
    return this.http.put(
      environment.baseUrl+`business/website/url`,data
    )
  }

  updateSEOTagsFromSpecificWebsite(data:any){
    return this.http.put(
      environment.baseUrl+`business/website/seo`,data
    )
  }

  linkExistingDomain(data:any){
    return this.http.put(
      environment.baseUrl+`business/website/url/domain`,data
    )
  }

  //entri related api integrations

  gettinEntriToken(data){
    return this.http.post(
      environment.businessWithOutGateway+`business/entri/token`,data
    )
  }

  callBackEntriMethod(data){
    return this.http.post(
      environment.businessWithOutGateway+`business/entri/callback`,data
    )
  }

  sellEntriToken(){
    return this.http.get(
      environment.baseUrl+`business/business/entri/sell/token`
    )
  }



  //search domain from godaddy
  getAllDomains(data:any){
    return this.http.get(
      environment.baseUrl+`domain/suggest?text=${data}`
    )
  }

  //crm contact leads
  getContactLeads(businessId:any){
    return this.http.get(
      environment.baseUrl+`business/contact?businessId=${businessId}&size=1000&page=0`
    )
  }

  typeText(data:any,templateData:any){
    // data.style = {
    //   "color":templateData.textColor,
    //   "font-family":templateData.fontFamily,
    // }
    // console.log(templateData);
    if(this.screenWidth > 475){
      if(data.textType === 'heading'){
        data.style = {
          "color":templateData.colors.txtHeadingColor,
          "font-family":templateData.headingFontFamily,
          "font-size":"28px",
          "font-weight":"bold",
          "line-height":"40px",
          "letter-spacing":"0px"
        }
      }
      else if(data.textType === 'description'){
        data.style = {
          "color":templateData.colors.txtDescColor,
          "font-family":templateData.bodyFontFamily,
          "font-size":"16px",
          "font-weight":"500",
          "line-height":"26px",
          "letter-spacing":"0px"
        }
      }
      else if(data.textType === 'text'){
        data.style = {
          "color":templateData.colors.txtNormalColor,
          "font-family":templateData.bodyFontFamily,
          "font-size":"18px",
          "font-weight":"700",
          "line-height":"28px",
          "letter-spacing":"0px"
        }
      }
      return data;
    }
    else{
      if(data.textType === 'heading'){
        data.style = {
          "color":templateData.colors.txtHeadingColor,
          "font-family":templateData.headingFontFamily,
          "font-size":"20px",
          "font-weight":"bold",
          "line-height":"40px",
          "letter-spacing":"0px"
        }
      }
      else if(data.textType === 'description'){
        data.style = {
          "color":templateData.colors.txtDescColor,
          "font-family":templateData.bodyFontFamily,
          "font-size":"14px",
          "font-weight":"500",
          "line-height":"26px",
          "letter-spacing":"0px"
        }
      }
      else if(data.textType === 'text'){
        data.style = {
          "color":templateData.colors.txtNormalColor,
          "font-family":templateData.bodyFontFamily,
          "font-size":"16px",
          "font-weight":"700",
          "line-height":"28px",
          "letter-spacing":"0px"
        }
      }
      return data;
    }
  }


  typeButton(data:any,templateData:any){
    if(this.screenWidth > 475){
      if(templateData.buttonStyle === "SOLID"){
        if(templateData.buttonShape === "SHARP"){
          data.style = {
            "background-color":templateData.colors.btnColor,
            "color":templateData.colors.btnTextColor,
            "font-family":templateData.headingFontFamily,
            "border-radius":"none",
            "padding":"8px 15px",
            "border":"none",
            "height":"44px",
            "font-size":"14px",
            "font-weight":"700",
            "letter-spacing":"0px"
          }
        }
        else{
          data.style = {
            "background-color":templateData.colors.btnColor,
            "color":templateData.colors.btnTextColor,
            "font-family":templateData.headingFontFamily,
            "border-radius":`10px`,
            "padding":"8px 15px",
            "border":"none",
            "height":"44px",
            "font-size":"14px",
            "font-weight":"700",
            "letter-spacing":"0px"
          }
        }
      }
      else{
        if(templateData.buttonShape === "SHARP"){
          data.style = {
            "background-color":"transparent",
            "color":templateData.colors.btnColor,
            "font-family":templateData.headingFontFamily,
            "border":`2px solid ${templateData.colors.btnColor}`,
            "padding":"8px 15px",
            "height":"44px",
            "font-size":"14px",
            "font-weight":"700",
            "letter-spacing":"0px"
            // "border-radius":"none"
          }
        }
        else{
          data.style = {
            "background-color":"transparent",
            "color":templateData.colors.btnColor,
            "font-family":templateData.headingFontFamily,
            "border-radius":`10px`,
            "padding":"8px 15px",
            "border":`2px solid ${templateData.colors.btnColor}`,
            "height":"44px",
            "font-size":"14px",
            "font-weight":"700",
            "letter-spacing":"0px"
          }
        }
      }
      // if(templateData.buttonStyle === "OUT_LINE"){
      //   data.style = {
      //     "background-color":"transparent",
      //     "color":templateData.colors.btnColor,
      //     "font-family":templateData.headingFontFamily,
      //     "border":`2px solid ${templateData.colors.btnColor}`,
      //     "padding":"8px 15px",
      //     "height":"44px",
      //     "font-size":"14px",
      //     "font-weight":"700",
      //     "letter-spacing":"0px"
      //     // "border-radius":"none"
      //   }
      // }
      return data;
    }
    else{
      if(templateData.buttonStyle === "SOLID"){
        if(templateData.buttonShape === "SHARP"){
          data.style = {
            "background-color":templateData.colors.btnColor,
            "color":templateData.colors.btnTextColor,
            "font-family":templateData.headingFontFamily,
            "border-radius":"none",
            "padding":"8px 14px",
            "border":"none",
            "height":"36px",
            "font-size":"14px",
            "font-weight":"700",
            "letter-spacing":"0px"
          }
        }
        else{
          data.style = {
            "background-color":templateData.colors.btnColor,
            "color":templateData.colors.btnTextColor,
            "font-family":templateData.headingFontFamily,
            "border-radius":`10px`,
            "padding":"8px 14px",
            "border":"none",
            "height":"36px",
            "font-size":"14px",
            "font-weight":"700",
            "letter-spacing":"0px"
          }
        }
      }
      else{
        if(templateData.buttonShape === "SHARP"){
          data.style = {
            "background-color":"transparent",
            "color":templateData.colors.btnTextColor,
            "font-family":templateData.headingFontFamily,
            "border":`2px solid ${templateData.colors.btnColor}`,
            "padding":"8px 14px",
            "height":"36px",
            "font-size":"14px",
            "font-weight":"700",
            "letter-spacing":"0px"
            // "border-radius":"none"
          }
        }
        else{
          data.style = {
            "background-color":"transparent",
            "color":templateData.colors.btnColor,
            "font-family":templateData.headingFontFamily,
            "border-radius":`10px`,
            "padding":"8px 14px",
            "border":`2px solid ${templateData.colors.btnColor}`,
            "height":"36px",
            "font-size":"14px",
            "font-weight":"700",
            "letter-spacing":"0px"
          }
        }
      }
      if(templateData.buttonStyle === "OUT_LINE"){
        data.style = {
          "background-color":"transparent",
          "color":templateData.colors.btnColor,
          "font-family":templateData.headingFontFamily,
          "border":`2px solid ${templateData.colors.btnColor}`,
          "padding":"8px 14px",
          "height":"36px",
          "font-size":"14px",
          "font-weight":"700",
          "letter-spacing":"0px"
          // "border-radius":"none"
        }
      }
      return data;
    }
  }

  getCountries(pageNo:any,size:any,text:any){
    return this.http.get(
      environment.serverIp+`master/location/country?size=${size}&page=${pageNo}&text=${text}&active=true`
    )
  }


  getStates(pageNo: any , size: any , text: any){
    return this.http.get(
      environment.serverIp + `master/location/state?pageNo=${pageNo}&size=${size}&text=${text}`
    )
  }

  getCities(pageNo: any , size :any, text:any){
    return this.http.get(
      environment.serverIp + `master/location/city?pageNo=${pageNo}&size=${size}&text=${text}`
    )
  }

  //Google analytics
  auth2CallBack(){
    return this.http.post(
      environment.serverIp+`/GA/oauth2callback`,{
        code: "4/0AbUR2VM5IM3Ehc1i91Pr4QKJUnFx9bwpbfLlh6DLMBGm3YNRawM1Cq3CgeZbUkAi4JDQ-Q&scope=https://www.googleapis.com/auth/analytics.edit%20https://www.googleapis.com/auth/analytics.readonly%20https://www.googleapis.com/auth/analytics"
      }
    )
  }

  reportBatch(data:any){
    return this.http.post(
      environment.serverIp+`/GA/batch/report?sDate=${data.startDate}&eDate=${data.endDate}&token=${data.token}&measurementId=${data.measurmentId}`,{}
    )
  }

  refreshToken(token:any){
    return this.http.get(
      environment.serverIp+`/GA/oauth2/refresh?refreshToken=${token}`
    )
  }

  openSnack(message: any, action: any) {
    this.snackbar.open(message, action, { duration: 5000 });
  }
  contactUs(data){
    return this.http.post(
      environment.baseUrl+`business/contact/simpo`,data
    )
  }



  //v2- apis

    getAllLocalElements(){
    return this.http.get(
      environment.serverIp+`master/template/v2/component/images`
    )
  }


  openBottomSheet(componentName: any,data:any){
    return this. _bottomSheet.open(componentName, {
      data:data,

    });
  }

  // texteditor-card generate button

  regenerate(data:any){
    return this.http.post(
      environment.serverIp + `/open-ai/integration/suggest/website/content`,data
    )
  }

  regenerateIndividual(data:any){
    return this.http.post(
    environment.regenerateUrl + `content/suggest/website/individual`,data
    )
  }
  regenerateIndividualV2(data:any){
    return this.http.post(
    environment.regenerateUrl + `content/suggest/v2/website/individual`,data
    )
  }
  getCategoryList(size: number, page: number){
    return this.http.get(
    environment.serverIp + `master/template/category?size=${size}&page=${page}`
    )
  }

  getBusinessDetailsById(id:any){
    return this.http.get(
      environment.baseUrl+`business/business/${id}`
    )
  }

  getWebsiteThemeById(themeId: string): Observable<PageTheme> {
    return this.http.get(environment.serverIp + `master/template/v2/theme?themeId=${themeId}`)
    .pipe(map((res: any)=> new PageTheme(res.data)), catchError(({ error }) => {
      throw error;
    }));
  }
  getAllThemes(): Observable<PageTheme[]> {
    return this.http.get(environment.serverIp + `master/template/v2/all-themes`)
    .pipe(map((res: any)=> res.data.map((data)=> new PageTheme(data))), catchError(({ error }) => {
      throw error;
    }));
  }
  applyThemeChanges(data: any) {
    return this.http.put(environment.serverIp + `master/website/V2/style`, data);
  }


  //analytics api integration\

  getAnalyticsDashboard(businessId,startDate,endDate){
    return this.http.get(
      environment.baseBusinessUrl+`analytics/${businessId}/dashboard?startDate=${startDate}&endDate=${endDate}`
    )
  }

  uploadFiles(formData: FormData){
    const headers = new HttpHeaders();
    headers.set('Accept','application/json');
    headers.delete('Content-type');
    return this.http.post(
      environment.baseUrl+`crm/leads/get/excel-headers`,
      formData,{headers: headers}
    )
  }
}
