import { EventEmitter, Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class EventEmmiterService {

  constructor() { }

  componentLoader = new EventEmitter<any>();
  globalRegenerate = new EventEmitter<any>();
  loadingSections = new EventEmitter<any>();
  activateComponent = new EventEmitter<any>();
  updateListingDefault = new EventEmitter<any>();
  updateBusinessName = new EventEmitter<any>();
  regenerateComponent = new EventEmitter<any>();
  editSection = new EventEmitter<any>();
  changeContent = new EventEmitter<any>();
  buttonCick = new EventEmitter<any>();
  addNewSectionCick = new EventEmitter<any>();
  pageLevelEdit = new EventEmitter<any>();
  sendingPageData = new EventEmitter<any>();
  gettingButtonClickValues = new EventEmitter<any>();
  componentPositionMoving = new EventEmitter<any>();
  componentDelete = new EventEmitter<any>();
  saveChanges = new EventEmitter<any>();
  switchBusiness = new EventEmitter<any>();
  sendingNewPageData = new EventEmitter<any>();
  goTosettings = new EventEmitter<any>();
  goToblogs = new EventEmitter<any>();
  goToLeads = new EventEmitter<any>();
  goToDashboard = new EventEmitter<any>();
  goToInvoices = new EventEmitter<any>();
  discardChanges = new EventEmitter<any>();
  pageNameChange = new EventEmitter<any>();
  mobileEditorOpen = new EventEmitter<any>();
  localDiscardChanges = new EventEmitter<any>();
  tabChange = new EventEmitter<any>();
  changeAnimation = new EventEmitter<any>();
  showSaveOption = new EventEmitter<any>();
  instanceEmitter = new EventEmitter<any>();
  logoUpdated = new EventEmitter<any>();
  businessUpdated = new EventEmitter<any>();
  notificationUpdate = new EventEmitter<any>();
  backbuttonClicked = new EventEmitter<any>();
  updatingViewEnquiry = new EventEmitter<any>();
}
