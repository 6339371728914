import { Component, ViewChild, ViewContainerRef } from '@angular/core';
import { Subscription } from 'rxjs';
import { EventsService } from 'simpo-component-library';
import { CrmServiceService } from 'src/app/master-config-components/micro-apps/crm/service/crm-service.service';
import { WebsiteGenerationService } from 'src/app/website-generation/website-generation.service';
import { cashfreeSandbox, cashfreeProd } from "cashfree-pg-sdk-javascript";
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-view-admission-form',
  templateUrl: './view-admission-form.component.html',
  styleUrls: ['./view-admission-form.component.scss']
})
export class ViewAdmissionFormComponent {
  constructor(
    private crmservice: CrmServiceService,
    private websiteGenerationService: WebsiteGenerationService,
    private _eventService: EventsService,
    private router: ActivatedRoute
  ) {}

  @ViewChild('displayWebsite', { read: ViewContainerRef })
  displayWebsite: ViewContainerRef | any;
  businessDetails : any ;
  admissionFormComponent: any[] = []
  templateId: string = '';
  businessId: string = '';

  ngOnInit() {
    // this.businessDetails = JSON.parse(window.localStorage.getItem('bDetails') || '');
    this.router.queryParams.subscribe((param: any) => {
      this.templateId = param['tId'];
      this.businessId = param['bId'];
    })

    this.getAdmissionForm();
    this.loadCashfreeSdk();
  }

  ngOnDestroy() {
    this.cashFreeEvent?.unsubscribe();
  }

  getAdmissionForm() {
    this.crmservice.getWebsiteTemplate(this.templateId).subscribe((res: any) => {
        for(let component of res?.data[0]?.components) {
          if(component.sectionType.toLowerCase() === 'header') {
            this.admissionFormComponent[0] = component;
          }
          if(component.sectionType.toLowerCase() === 'admissionform') {
            this.admissionFormComponent[1] = component;
          }
          if(component.sectionType.toLowerCase() === 'footer') {
            this.admissionFormComponent[2] = component;
          }
        }
        this.displayForm();
    })
  }

  displayForm(){
    setTimeout(() => {
      const instance = this.websiteGenerationService.createDynamicComponents(this.displayWebsite, this.admissionFormComponent, false);
    }, 500);
  }

  cashFreeEvent?: Subscription;
  loadCashfreeSdk() {
    this.cashFreeEvent = this._eventService.cashFreeEvent.subscribe((res: any) => {
      const paymentSessionId = res.response.data.sessionId;
      localStorage.setItem("admissionOrderId", res.response.data.orderId);

      let cashFree;
      // if (environment.production) {
      //   cashFree = new cashfreeProd.Cashfree(paymentSessionId);
      // } else {
        cashFree = new cashfreeSandbox.Cashfree(paymentSessionId);
      // }
      cashFree.redirect();
    })
  }
}
