import { Component, ElementRef, HostListener, Input, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, Subscription, map, startWith } from 'rxjs';
import { DbService } from 'src/app/services/db-service/db.service';
import { DynamicpickcomponentsService } from 'src/app/services/dynamicpickcomponents.service';
import { EventEmmiterService } from 'src/app/services/event-emmiter.service';
import { MapService } from 'src/app/services/map.service';
import { TemplateService } from 'src/app/services/template.service';
import {
  buttonShape,
  buttonStyle,
  v3FontFamilies
} from 'src/app/constants/common-constant';
import { AuthServiceService } from 'src/app/services/auth-service.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ListCustomElementsComponent } from 'src/app/master-config-components/micro-apps/website/edit-website/v-2/list-custom-elements/list-custom-elements.component';
import { Industry, StorageKeys } from 'src/app/shared-module-files/simpo.constant';
import { SingupPopupComponent } from './../../our-components/signin-signup/singup-popup/singup-popup.component';
import { BusinessServiceService } from 'src/app/master-config-components/micro-apps/business/services/business-service.service';
import { WebisiteService } from 'src/app/master-config-components/micro-apps/website/webisite.service';
import { SocialLoginService } from 'src/app/master-config-components/micro-apps/website-marketing/services/social-login-service.service';
import { WebsiteGenerationService } from '../website-generation.service';
import { FontFamily } from 'src/app/master-config-components/micro-apps/website/constants/FontFamilty.constant';
import { WebsiteEditor } from '../website-editor.service';
import { ALIGN, EventsService, FooterTypes, ImageRatio, TextPosition } from 'simpo-component-library';
import { Section } from 'src/app/master-config-components/micro-apps/website/edit-website/v-2/editor/editor.modal';
import { EcommerceService } from 'src/app/services/ecommerce.service';
import { StaffServiceService } from 'src/app/master-config-components/micro-apps/staff/service/staff-service.service';
import { SubscriptionService } from 'src/app/services/subscription.service';

@Component({
  selector: 'app-v3-website-generation',
  templateUrl: './v3-website-generation.component.html',
  styleUrls: ['./v3-website-generation.component.scss']
})
export class V3WebsiteGenerationComponent implements OnInit {
  constructor(
    private _templateService: TemplateService,
    private _dbService: DbService,
    private router: Router,
    private pickupService: DynamicpickcomponentsService,
    private eventHelper: EventEmmiterService,
    private _mapservice: MapService,
    private _authService: AuthServiceService,
    private _snackBar: MatSnackBar,
    private _activeRoute: ActivatedRoute,
    private _businessService: BusinessServiceService,
    private _websiteService: WebisiteService,
    private socialLogin: SocialLoginService,
    private _webisteGenerationService: WebsiteGenerationService,
    private templateService: TemplateService,
    private websiteEditorService: WebsiteEditor,
    private eventDirective: EventsService,
    private ecomService: EcommerceService,
    private staffService: StaffServiceService,
    private subscriptionService: SubscriptionService
  ) {
    this.getScreenSize();
    this.getAllTypes();
  }

  srcWidth: any;
  @Input() templatePage: any[] = []
  textAlign: ALIGN[] = ['left', 'center', 'right']
  imageRatio: ImageRatio[] = []
  textPosition: TextPosition[] = [];
  footerType: FooterTypes[] = []
  @HostListener('window:resize', ['$event'])
  @ViewChild('container') containerRef!: ElementRef;
  @ViewChild('displayWebsite', { read: ViewContainerRef })
  displayWebsite: ViewContainerRef | any;
  fontFamilies = v3FontFamilies
  getScreenSize(event?) {
    this.srcWidth = window.innerWidth;
  }

  ngOnInit(): void {
    this.business = JSON.parse(localStorage.getItem("business") || '[]');
    this.websiteId = localStorage.getItem(StorageKeys.WEBSITE_ID) || '';
    this.staffId = localStorage.getItem(StorageKeys.STAFF_ID) || '';
    this.googleTokenId = localStorage.getItem(StorageKeys.GOOGLETOKENID) || null;
    let industry: any = localStorage.getItem(StorageKeys.INDUSTRY_DETAILS);
    this.selectedIndustry = industry ? JSON.parse(industry) : this.selectedIndustry;
    this.enumValue()
    if (this.staffId) {
      this.urlShared = false;
      this.generateWebsite();
    }
    else if (this.websiteId && this._activeRoute.snapshot.queryParams['tempId'] === undefined) {
      this.urlShared = false;
      this.websiteId = localStorage.getItem(StorageKeys.WEBSITE_ID) || '';
      this.getWebsite();
      // console.log();
    }
    else {
      this._activeRoute.queryParams.subscribe(
        (param: any) => {
          if (Object.keys(param).length === 0) {
            this.urlShared = false;
            this.generateWebsite()
          }
          else {
            this.urlShared = true;
            this.websiteId = param.tempId;
            this.getWebsiteWithoutHover();
          }
        }
      )
    }
    this.getAllThemes();
  }

  ngOnDestroy() {
    this.popupSubscription?.unsubscribe();
    if (this.subscriptions) {
      this.subscriptions.forEach((item, j) => {
        item.unsubscribe();
      });
    }
  }

  @ViewChild('websiteStructure', { read: ViewContainerRef })
  websiteStructure: ViewContainerRef | any;
  templatePages: any[] = [];
  pageSeoslected: any;
  staffId: string = '';
  urlShared: boolean = false;

  websiteId: string = '';
  business: any[] = [];
  googleTokenId: any;
  googleEmail: any;
  selectedIndustry: Industry = {
    name: '',
    industryId: '',
    subIndustryId: '',
    industryName: '',
    subIndustryName: ''
  };

  textsForScreenLoader: string[] = [];

  // get website data based on website id
  getWebsite() {
    this.screenLoader = true
    this._templateService.v3getWebsiteWithWebCode(localStorage.getItem('webCode')).subscribe(
      (res: any) => {

        let data = {
          subIndustryId: this.selectedIndustry?.subIndustryId
        }
        const promises = res[0].components.map(async (section, index) => {
            if(section.type === 'E_COMMERCE') {
              data['sectionType'] = section.sectionType;
              data['collectionId'] = section?.content?.collectionId;
              section['responseData'] = await this.ecomService.getData(data);
            }
        });

        Promise.all(promises).then(() => {
          this.templatePages = res;
          this.currentPageId = res[0].id;
          this.currentPageIndex = 0;
          const instance: any = this._webisteGenerationService.createDynamicComponents(this.websiteStructure, res[0].components, true);
          this.initiateSubscription(instance)
          this.applyFontFamily();
          this.screenLoader = false;
        })

      },
      (err) => {
        this.screenLoader = false;
      }
    )
  }

  //get website without hover effects for share url
  webData: any;
  getWebsiteWithoutHover() {
    this._templateService.getWebsiteWithWebCode(this.websiteId).subscribe(
      (res: any) => {
        this.loadingText = ''
        this.templatePages = res.data;
        this.pageSeoslected = this.templatePages[0].id;
        this.path = res.data[0].path
        this.selectedPageData = res.data[0];
        this.webData = res.data;
        this.websiteData = res.data[0];

        //attaching current button styles
        this.buttonStyles.forEach((btnStyles) => {
          if (btnStyles.value === res.data[0].btnStyle) {
            this.bStyle = btnStyles.value
          }
        });

        //attaching current button shapes
        this.buttonShape.forEach((btnShapes) => {
          if (btnShapes.value === res.data[0].btnShape) {
            this.bShape = btnShapes.value;
          }
        });

        //attaching button color and button text color
        this.btnColor = res.data[0].btnColor;
        this.btnTextColor = res.data[0].btnTextColor;
        this.screenLoader = false;

        // this.dyanmicComponentPickup(this.websiteStructure,res.data,true);
        const instance: any = this.pickupService.dyanmicComponentPickup(this.websiteStructure, res.data[0].components, false)
        this.initiateSubscription(instance);
      },
      (err) => {
        this.screenLoader = false;
      }
    )
  }
  localNewPageArray: any[] = [];
  currentPageIndex: number = 0;
  currentPageId: any;
  addNewSectionCick(res: any) {
    let index;
    // this.addNewSectionClickSubscribe = this.eventHelper.addNewSectionCick.subscribe(
    //   (res) => {
    index = res.index;
    if (res.status) {
      let customElements = this._templateService.openDialog(
        ListCustomElementsComponent,
        '674px',
        '1017px',
        'auto'
      );
      customElements.afterClosed().subscribe(
        (res: any) => {
          if (res.responseData != '') {
            // this.localNewPageArray.forEach((la,i) => {
            if (this.localNewPageArray[1]?.webComponentName === 'new_page') {
              this.websiteStructure.clear();
              this.localNewPageArray.splice(1, 1);
              this.localNewPageArray.push(JSON.parse(JSON.stringify(res.responseData)));
              this.pickupService.dyanmicComponentPickup(this.websiteStructure, this.localNewPageArray, true);
            }
            else {
              // this.localNewPageArray.splice(index + 1,0,res.responseData);
              this.templatePages[this.currentPageIndex].components.splice(index + 1, 0, JSON.parse(JSON.stringify(res.responseData)));
              this.pickupService.addComponent(this.websiteStructure, JSON.parse(JSON.stringify(res.responseData)), true, index + 1);
            }
            // });
          }
          else {
            if (res.localElement != '') {
              // console.log(index);
              // console.log(res.localElement);
              this._templateService.getLocalComponentWithData(index + 1, this.templatePages[0].id, res.localElement.componentId, this.websiteData.subIndustryId, this.templatePages[this.currentPageIndex].id).subscribe(
                (res: any) => {
                  console.log(res.data);
                  if (this.localNewPageArray[1]?.webComponentName === 'new_page') {
                    this.websiteStructure.clear();
                    this.localNewPageArray.splice(1, 1);
                    this.localNewPageArray.push(res.data);
                    this.pickupService.dyanmicComponentPickup(this.websiteStructure, this.localNewPageArray, true);
                  }
                  else {
                    // this.localNewPageArray.splice(index+1,0,res.data);
                    this.templatePages[this.currentPageIndex].components.splice(index + 1, 0, res.data)
                    // this.totalWebsiteData[this.currentPageIndex].components.splice(index+1,0,res.data)
                    // console.log(this.localNewPageArray);
                    this.pickupService.addComponent(this.websiteStructure, res.data, true, index + 1)
                  }
                },
                (err) => {
                  this._templateService.openSnack(
                    `${err.error.message}`,
                    'Close'
                  )
                }
              )

            }
          }
        }
      )
    }
    //   }
    // )
  }

  //generate website tab
  websiteData: any;
  screenLoader: boolean = true;
  loadingText: any = '';
  poppperLoader: boolean = false;
  generateWebsite() {
    this.screenLoader = true;
    let payload = {
      subIndustryId: this.selectedIndustry?.subIndustryId ?? '',
      businessName: localStorage.getItem(StorageKeys.BUSINESS_NAME)
    }
    this._templateService.generateV3Website(payload).subscribe(
      (res: any) => {
        this.getComponentData(res);
      },

      (err) => {
        this.screenLoader = true;
        console.log(err)
      }
    )
  }

  getComponentData(res: any){
    let data = {
      subIndustryId: this.selectedIndustry?.subIndustryId
    }
    const promises = res.data[0].components.map(async (section, index) => {
        if(section.type === 'E_COMMERCE') {
          data['sectionType'] = section.sectionType;
          data['collectionId'] = section?.content?.collectionId;
          section['responseData'] = await this.ecomService.getData(data);
          console.log(data);
        }
    });

    Promise.all(promises).then(() => {
      const instance: any = this._webisteGenerationService.createDynamicComponents(this.websiteStructure, res.data[0].components, true);
        localStorage.setItem(StorageKeys.WEB_CODE, res.data[0].websiteCode);
        localStorage.setItem(StorageKeys.WEBSITE_ID, res.data[0].id);
        this.websiteId = res.data[0].id;
        this.currentPageId = res.data[0].id;
        this.currentPageIndex = 0;
        this.initiateSubscription(instance)
        this.templatePages = res.data;
        this.screenLoader = false;
        this.applyFontFamily();
    });
  }

  // signup tab which is appeace in bottom of screen
  signUp(type) {
    const websiteId = localStorage.getItem(StorageKeys.WEBSITE_ID);
    localStorage.setItem(StorageKeys.WEBSITE_STATUS, type)

    if (websiteId) {
      if (this.googleTokenId) {
        this.login()
        return;
      }
      // this.router.navigate(['/signup']);
      // this.openingSignupPopup()
      if (this.srcWidth > 475) {
        this.openingSignupPopup()
      }
      if (this.srcWidth < 475) {
        let bottom = this.templateService.openBottomSheet(
          SingupPopupComponent,
          '',
          ''
        )
      }
      window.localStorage.setItem('EXISTING', 'YES')
    } else {
      this._templateService.openSnack(
        'Website not yet generated, Getting error',
        'Ok'
      );
    }
  }

  regenerateButtonCountClick = 0;
  // component regenerate

  enumValue() {
    for (const value of Object.values(ImageRatio)) {
      this.imageRatio.push(value);
    }

    for (const value of Object.values(TextPosition)) {
      this.textPosition.push(value);
    }

    for (const value of Object.values(FooterTypes)) {
      this.footerType.push(value);
    }
  }


  regenerateComponent(response: any) {
    let index = response.index;
    let component = this.templatePages[0].components[index];

    if (component.sectionType === 'banner' || component.sectionType === 'text_image') {
      let index = component.styles.positionLayout.types.indexOf(component.styles.positionLayout.value) + 1;
      component.styles.positionLayout.value = component.styles.positionLayout.types[index % component.styles.positionLayout.types.length];
      this.eventDirective.postionLayoutChangeChecks.emit({ id: component.id, data: component.styles.positionLayout })
    }
    if (component.sectionType === 'FAQ' || component.sectionType === 'testimonial' || component.sectionType === 'text') {
      let index = this.textAlign.indexOf(component.styles.layout.align) + 1;
      component.styles.layout.align = this.textAlign[index % this.textAlign.length];
      this.eventDirective.alignmentChangeChecks.emit({ id: component.id, data: component.styles.layout })
    }
    if (component.sectionType === 'imagegrid') {
      if (!component.styles.removeGaps || !component.styles.fullWidth) {
        component.styles.removeGaps = true;
        component.styles.fullWidth = true;

      } else {
        let index = this.imageRatio.indexOf(component.styles.image.ratio) + 1;
        component.styles.image.ratio = this.imageRatio[index % this.imageRatio.length];
        this.eventDirective.imageStyleChangeChecks.emit({ id: component.id, style: component.styles.image })
      }
    }
    if (component.sectionType === 'image') {
      if (!component.styles.fullWidth) {
        component.styles.fullWidth = true
      }
    }
    if (component.sectionType === 'service' || component.sectionType === 'teammember') {
      let index = this.textPosition.indexOf(component.styles.textPosition) + 1;
      component.styles.textPosition = this.textPosition[index % this.textPosition.length];
    }
    if (component.sectionType === 'video') {
      component.styles.fullScreen = !component.styles.fullScreen;
      if (component.styles.layout.fit === 'content') {
        component.styles.layout.fit = 'screen'
      } else {
        component.styles.layout.fit = 'content'
      }
      this.eventDirective.spacingChangeChecks.emit({ id: component.id, data: component.styles.layout })
    }
    if (component.sectionType === 'footer') {
      let index = this.footerType.indexOf(component.styles.footerType) + 1;
      component.styles.footerType = this.footerType[index % this.footerType.length];
    }
  }

  regenerateContent(response: any) {
    this.updateSkeletonSection(response.index, response.sectionType)
    this.templateService.getSectionContent(response.sectionId, this.selectedIndustry?.subIndustryId, localStorage.getItem(StorageKeys.BUSINESS_NAME) || '').subscribe((res: any) => {
      let index = response.index;
      if (res?.data?.data) {
        this.templatePages[0].components[index].content = res.data.data;
        this.templatePages[0].components[index].action = res.data.action;

        const instance = this._webisteGenerationService.updateSection(this.websiteStructure, response.index, this.templatePages[this.currentPageIndex].components[index], true);
        this.initiateSubscription(instance)
      }
    })
  }

  updateSkeletonSection(index: number, sectionType: any) {
    let addSection = new Section();
    addSection.sectionType = 'section-loader';

    const instance = this._webisteGenerationService.updateSection(this.websiteStructure, index, addSection, false, sectionType);
    this.initiateSubscription(instance);
  }

  businessDetails: any;

  subscriptions: any[] = [];
  needUserToLogin: boolean = false;
  private initiateSubscription(instance: any) {
    this.subscriptions.forEach(element => {
      element.unsubscribe();
    });

    this.subscriptions.push(
      instance.addNewSectionClick.subscribe((response: any) => {
        // this.openingSignupPopup();
        if (this.staffId)
          this.createBusiness('NOT_PUBLISHED');
        else {
          if (this.srcWidth > 475) {
            this.openingSignupPopup()
          }
          if (this.srcWidth < 475) {
            let bottom = this.templateService.openBottomSheet(
              SingupPopupComponent,
              '',
              '',
            )
          }
        }
      })
    )
    this.subscriptions.push(
      instance.editSection.subscribe((response: any) => {
        if (this.staffId) {
          if (this.business)
            this.createBusiness('NOT_PUBLISHED');
          else
            this.eventHelper.editSection.emit(response);
        }
        else {
          if (this.srcWidth > 475) {
            this.openingSignupPopup()
          }
          if (this.srcWidth < 475) {
            let bottom = this.templateService.openBottomSheet(
              SingupPopupComponent,
              '',
              '',
            )
          }
        }
      })
    )
    this.subscriptions.push(
      instance.delete.subscribe((response: any) => {
        if (this.staffId) {

          this.deleteComponent(response);
        }
        else {
          if (this.srcWidth > 475) {
            this.openingSignupPopup()
          }
          if (this.srcWidth < 475) {
            let bottom = this.templateService.openBottomSheet(
              SingupPopupComponent,
              '',
              '',
            )
          }
        }
      })
    )

    this.subscriptions.push(
      instance.restyleSection.subscribe((res: any) => {

        this.regenerateButtonCountClick = this.regenerateButtonCountClick + 1;

        if (this.regenerateButtonCountClick <= 5) {
          this.regenerateComponent(res)
        }
        if (this.regenerateButtonCountClick > 5) {
          if (this.srcWidth > 475) {
            this.openingSignupPopup()
          }
          if (this.srcWidth < 475) {
            let bottom = this.templateService.openBottomSheet(
              SingupPopupComponent,
              '',
              '',
            )
          }
        }
      })
    )
    this.subscriptions.push(
      instance.changeContent.subscribe((res: any) => {
        this.regenerateButtonCountClick = this.regenerateButtonCountClick + 1;
        if (this.regenerateButtonCountClick <= 5) {
          this.regenerateContent(res);
        }
        if (this.regenerateButtonCountClick > 5) {
          if (this.srcWidth > 475) {
            this.openingSignupPopup()
          }
          if (this.srcWidth < 475) {
            let bottom = this.templateService.openBottomSheet(
              SingupPopupComponent,
              '',
              '',
            )
          }
        }
      })
    )

    this.subscriptions.push(
      instance.duplicate.subscribe((res: any) => {
        if (this.staffId) {

        } else {
          this.openingSignupPopup()
        }
      })
    )
    this.subscriptions.push(
      instance.changePosition.subscribe((res: any) => {
        this.changeSectionPosition(res);
      })
    )
  }

  changeSectionPosition(response: any) {
    let index = response.index
    let swappedIndex = response.position === 'UP' ? response.index - 1 : response.index + 1;
    let currentPage = this.templatePages[0];

    if (currentPage.components[swappedIndex].sectionType === 'footer') {
      return;
    }

    [currentPage.components[index], currentPage.components[swappedIndex]] = [currentPage.components[swappedIndex], currentPage.components[index]];

    const instance = this._webisteGenerationService.createDynamicComponents(this.websiteStructure, this.templatePages[0].components, true);
    this.initiateSubscription(instance);
  }

  selectedPageData: any;
  path: any;
  buttonCickedChecks(res: any) {
    if (res.buttonData.redirectionType === 'SAME_PAGE') {
      this.pickupService.samePageRedirection(this.selectedPageData, res.buttonData.redirectTo);
    }
    else if (res.buttonData.redirectionType === 'INTERNAL') {
      this.websiteStructure.clear()
      this.webData.forEach(webData => {
        if (res.buttonData.redirectTo === webData.path) {
          if (webData.path != '' && webData.components[0].category.name != 'Top Nav Bar') {
            webData.components.splice(0, 0, this.websiteData[0].components[0])
            // console.log(webData.components.length)
            webData.components.splice(webData.components.length, 0, this.websiteData[0].components[this.websiteData[0].components.length - 1]);
          }
          else { }
          this.pickupService.dyanmicComponentPickup(this.websiteStructure, webData.components, false);
          this.path = webData.path;
          this.selectedPageData = webData;
        }
      });
    }
    else {
      this.pickupService.externelUrls(res.buttonData.redirectTo)
    }
  }

  popupSubscription?: Subscription;
  openingSignupPopup() {
    if(!localStorage.getItem(StorageKeys.WEBSITE_STATUS)) {
      localStorage.setItem(StorageKeys.WEBSITE_STATUS, "PUBLISHED")
    }
    let dialogRef;
    if (this.srcWidth > 475) {
      dialogRef = this._templateService.openDialog(
        SingupPopupComponent,
        'auto',
        '900px',
        { data: null }
      )
    }
    else {
      let bottom = this.templateService.openBottomSheet(
        SingupPopupComponent,
        '',
        '',
      )
    }
    this.popupSubscription?.unsubscribe();
    this.popupSubscription = dialogRef?.afterClosed().subscribe((response: any) => {
      this.pageSeoslected = this.templatePages[0].id;
    })
  }

  deleteComponent(res: any) {
    let index = res.index;
    this.templatePages[this.currentPageIndex].components.splice(index, 1);
    this.pickupService.dyanmicComponentPickup(this.websiteStructure, this.templatePages[this.currentPageIndex].components, true);
    if ((this.templatePages[this.currentPageIndex].components.length === 1 && this.templatePages[this.currentPageIndex].components[0].category.name === 'Top Nav Bar') ||
      (this.templatePages[this.currentPageIndex].components.length === 2 && this.templatePages[this.currentPageIndex].components[1].category.name === 'Footer"')) {
      let newPageObject = {
        webComponentName: 'new_page',
        category: {
          name: 'new_page'
        }
      };
      this.templatePages[this.currentPageIndex].components.push(JSON.parse(JSON.stringify(newPageObject)));
      this.pickupService.dyanmicComponentPickup(this.websiteStructure, this.templatePages[this.currentPageIndex].components, true);
    }
  }

  componentPositionMove(res: any) {
    // this.componentPositionMoveSubscribe = this.eventHelper.componentPositionMoving.subscribe(
    //   (res:any) => {
    let indexA;
    let indexB;
    if (res.movingDirection === 'DOWN') {
      indexA = res.index;
      indexB = res.index + 1;
    }
    else if (res.movingDirection === 'UP') {
      indexA = res.index;
      indexB = res.index - 1;
    }
    let arr = this.templatePages[this.currentPageIndex].components;
    arr[indexA] = arr.splice(indexB, 1, arr[indexA])[0];
    this.pickupService.dyanmicComponentPickup(this.websiteStructure, this.templatePages[this.currentPageIndex].components, true);
    setTimeout(() => {
      const element = document.getElementById(this.templatePages[this.currentPageIndex].components[indexB].name);
      element?.scrollIntoView({
        behavior: 'smooth'
      });
    }, 100);
    //   }
    // )
  }

  //change component content

  changeComponentContent(res: any) {
    // this._eventHelper.changeContent.subscribe(
    //   (res:any) => {
    this.regenerateButtonCountClick = this.regenerateButtonCountClick + 1
    if (this.regenerateButtonCountClick > 5) {
      // this.router.navigate(['/signup']);
      this.openingSignupPopup();
    }
    else {
      if (res.changeContent) {
        let data = {
          index: res.indexValue,
          templateId: this.websiteData.id,
          subIndustryId: this.selectedIndustry?.subIndustryId ?? ''
        }
        this._templateService.changeComponentContent(data).subscribe(
          (comingData: any) => {
            this.websiteData.components.splice(res.indexValue, 1, comingData.data);
            this.pickupService.dyanmicComponentPickup(this.websiteStructure, this.websiteData.components, true);
          },
          (err) => {
            this._templateService.openSnack(
              `${err.error.message}`,
              'Close'
            )
          }
        )
      }
    }
  }

  // page change event
  pageSelectionChange(ev) {
    document.getElementById('closeaddPageBottomSheet')?.click()
    if (!this.staffId) {
      // this.router.navigate(['/signup']);
      setTimeout(() => {
        this.pageSeoslected = this.templatePages[0].id;
        this.openingSignupPopup();
      }, 10);
    } else {
      this.createBusiness('NOT_PUBLISHED');
    }
  }


  // global regenerate structure and website
  globalRegenerateComponent: boolean = false;

  searchLocation: any =
    `${localStorage.getItem(StorageKeys.LOCALITY)}` + ',' + `${localStorage.getItem(StorageKeys.STATE)}`;
  searchBox = new FormControl();
  locations: any = [];
  searchResults: Observable<string[]> | any;
  selectedLocation: any;
  myControl = new FormControl();
  filteredOptions: Observable<string[]> | any;
  allTypes: any = [];

  globalRegenrate() {
    this.globalRegenerateComponent = true;
  }

  //location change
  search() {
    this._mapservice.searchAddress(this.searchLocation).subscribe(
      (res: any) => {
        let locations: any = [];
        res.features.forEach((location: any) => {
          locations.push({
            displayName:
              location?.place_name.split(', ')[0] +
              ',' +
              location?.context[0]?.text +
              ',' +
              location?.context[2]?.text,
            latlng: location?.center,
          });
        });
        this.locations = locations;
        this.searchResults = this.searchBox.valueChanges.pipe(
          startWith(''),
          map((value) => this._searchFilter(value))
        );
      },
    );
  }
  private _searchFilter(value: any) {
    const filterValue = value.toLowerCase();

    return this.locations.filter((option: any) =>
      option.displayName.toLowerCase().includes(filterValue)
    );
  }

  selectedLocations(option: any) {
    this.selectedLocation = option;
    let splitedLocation = this.selectedLocation.displayName.split(',');
    localStorage.setItem(StorageKeys.LOCALITY, splitedLocation[0])
    localStorage.setItem(StorageKeys.STATE, splitedLocation[1]);
  }

  //industry type change

  getAllTypes() {
    this._templateService.mergedIndustrySubIndustry().subscribe(
      (res: any) => {
        // //console.log(res.data);
        this.filteredOptions = this.myControl;
        // this.allTypes = res.data;
        res.data.forEach((data: any) => {
          this.allTypes.push({
            name: data.parent.name + ' - ' + data.name,
            industryId: data.parent.id,
            subIndustryId: data.id,
            industryName: data.parent.name,
            subIndustryName: data.name,
          });
        });
        this.filteredOptions = this.myControl.valueChanges.pipe(
          startWith(''),
          map((value) => this._filter(value))
        );
      },
      (err) => {
        // ////console.log(err)
        this._templateService.openSnack(`${err.error.message}`, 'Close');
      }
    );
  }
  private _filter(value: any) {
    const filterValue = value.toLowerCase();

    return this.allTypes.filter((option: any) =>
      option.name.toLowerCase().includes(filterValue)
    );
  }

  selectBusinessType(ev: Industry) {
    this.selectedIndustry = ev;
    localStorage.setItem(StorageKeys.INDUSTRY_DETAILS, JSON.stringify(ev));
  }

  //businessName change
  bName: any = localStorage.getItem(StorageKeys.BUSINESS_NAME);

  cancelRegenerate() {
    this.globalRegenerateComponent = false;
  }

  doneGlobalRegenerate() {
    if (this.subscriptions) {
      this.subscriptions.forEach((item, j) => {
        item.unsubscribe();
      });
    }
    {
      this.screenLoader = true;
      localStorage.setItem(StorageKeys.BUSINESS_NAME, this.bName);
      localStorage.removeItem('templateId');
      this.globalRegenerateComponent = false;
      this.websiteStructure.clear();
      this.generateWebsite();
    }
    this.regenerateButtonCountClick = this.regenerateButtonCountClick + 1
    if (this.regenerateButtonCountClick > 5) {
      this.openingSignupPopup();
      this.screenLoader = false;
      this.generateWebsite();
    }
  }

  shareWebsite() {
    // if (this.websiteId) {
    //   this._templateService.openDialog(ShareWebsiteComponent, 'auto', '490px', {
    //     webId:
    //       window.location.href +
    //       `?tempId=${localStorage.getItem(StorageKeys.WEB_CODE)}`,
    //   });
    // } else {
    //   this._templateService.openSnack('Website Not Yet Generated', 'Ok');
    // }
  }


  //global restyle functionlities starts

  positionRestyleTop: any = '';
  positionRestyleRight: any = '';
  restyleComponent: boolean = false;
  bStyle: any;
  buttonStyles = buttonStyle;
  bShape: any;
  buttonShape = buttonShape;
  btnColor: any;
  btnTextColor: any;
  openRestyleComponent() {
    this.restyleComponent = true;
    let a = document.getElementById('restyle_component');
    let position = a?.getBoundingClientRect();
    this.positionRestyleRight = position?.top;
    this.positionRestyleTop = position?.right;
  }
  cancelRestyleComponent() {
    this.restyleComponent = false;
  }

  saveGlobalRestyle() {
    let data = {
      homePageId: this.websiteData.id,
      btnShape: this.bShape,
      btnStyle: this.bStyle,
      btnColor: this.btnColor,
      btnTextColor: this.btnTextColor
    }
    this._templateService.reStylePages(data).subscribe(
      (res: any) => {
        // console.log(res.data);
        this.websiteStructure.clear();
        this.templatePages = res.data;
        this.pageSeoslected = this.templatePages[0].id;

        this.websiteData = res.data[0];

        //attaching current button styles
        this.buttonStyles.forEach((btnStyles) => {
          if (btnStyles.value === res.data[0].btnStyle) {
            this.bStyle = btnStyles.value
          }
        });

        //attaching current button shapes
        this.buttonShape.forEach((btnShapes) => {
          if (btnShapes.value === res.data[0].btnShape) {
            this.bShape = btnShapes.value;
          }
        });

        //attaching button color and button text color
        this.btnColor = res.data[0].btnColor;
        this.btnTextColor = res.data[0].btnTextColor;


        // this.dyanmicComponentPickup(this.websiteStructure,res.data,true);
        this.pickupService.dyanmicComponentPickup(this.websiteStructure, res.data[0].components, true)
        this.restyleComponent = false;
      },
      (err) => {
        console.log(err.error.message);
      }
    )
  }
  goBack() {
    if (!this.staffId) {
      this.router.navigate(['/business-type']);
    } else
      this.router.navigate(['/list-business'])
  }

  login() {
    this.screenLoader = true;

    this.textsForScreenLoader = [
      'Website is being published, please wait',
      'Website is ready to publish'
    ];

    var data = {
      businessName: localStorage.getItem(StorageKeys.BUSINESS_NAME),
      address: {
        city: localStorage.getItem(StorageKeys.LOCALITY),
        geolocation: {
          latitude: localStorage.getItem(StorageKeys.LATITUDE),
          longitude: localStorage.getItem(StorageKeys.LONGITUDE)
        }
      },
      industryName: this.selectedIndustry.industryName,
      industryId: this.selectedIndustry.industryId,
      subIndustryId: this.selectedIndustry.subIndustryId,
      subIndustryName: this.selectedIndustry.subIndustryName,
      templateId: this.websiteId,
      countryId: window.localStorage.getItem('countryId'),
      countryCode: window.localStorage.getItem('countryCode'),
      googleToken: this.googleTokenId
    }

    this._authService.login(data).subscribe(
      (res: any) => {
        localStorage.removeItem(StorageKeys.GOOGLETOKENID);

        localStorage.setItem(StorageKeys.STAFF_ID, res.data.id);
        localStorage.setItem(StorageKeys.STAFF_NAME, res.data.name);
        this.screenLoader = false;
        this._dbService.setFname(res.data.name)
        localStorage.setItem(StorageKeys.BUSINESS_NAME, res.data.business[0].name);
        window.localStorage.setItem('business', JSON.stringify(res.data.business));
        if (res.data.business.length === 1) {
          this._dbService.setBusinessId(res.data.business[0].id);
          this.getBusinessDetailsById(res.data.business[0].id);
        } else {
          let a = res.data.business.find(e => e.favourite);
          if (a) {
            this.getBusinessDetailsById(a.id);
            this._dbService.setBusinessId(a.id);
          } else {
            this.router.navigate(['/list-business']);
          }
        }
      },
      (err) => {
        this.screenLoader = false;
      }
    );
  }

  //create new business
  createBusiness(type: "NOT_PUBLISHED" | "PUBLISHED", action?: any) {
    this.screenLoader = true;
    if (type == "PUBLISHED") {
      this.textsForScreenLoader = [
        'Website is being published, please wait',
        'Website is ready to publish'
      ];
    } else {
      this.textsForScreenLoader = [
        'Website is being saved, please wait',
        'Website is ready you can edit in website section'
      ];
    }

    window.localStorage.setItem(StorageKeys.WEBSITE_STATUS, type)
    let data : any = {
      businessName: localStorage.getItem(StorageKeys.BUSINESS_NAME),
      address: {
        city: localStorage.getItem(StorageKeys.LOCALITY) ?? "Hyderabad",
        geolocation: {
          latitude: localStorage.getItem(StorageKeys.LATITUDE) ?? "17.360589",
          longitude: localStorage.getItem(StorageKeys.LONGITUDE) ?? "78.474061"
        },
        state: localStorage.getItem(StorageKeys.STATE) ?? "Telangana",
        country: window.localStorage.getItem('count') ?? 'INDIA',
        pincode: window.localStorage.getItem('postalCode') ?? "500032",
      },
      industryName: this.selectedIndustry?.industryName ?? '',
      industryId: this.selectedIndustry?.industryId ?? '',
      subIndustryId: this.selectedIndustry?.subIndustryId ?? '',
      subIndustryName: this.selectedIndustry?.subIndustryName ?? '',
      templateId: this.websiteId,
      countryId: window.localStorage.getItem('countryId'),
      countryCode: window.localStorage.getItem('countryCode'),
      staffId: this.staffId,
      websiteStatus: type,
      affiliatePartnerKey : ""
    };
    if(localStorage.getItem("partnerId"))
    {
      data.affiliatePartnerKey  = localStorage.getItem("partnerId");
    }

    this._authService.v3CreateAccount(data).subscribe(
      (res: any) => {
        localStorage.removeItem('countryCode');
        localStorage.removeItem('countryId');
        localStorage.removeItem(StorageKeys.LOCALITY);
        localStorage.removeItem(StorageKeys.STATE);
        localStorage.removeItem(StorageKeys.BUSINESS_NAME);
        localStorage.removeItem(StorageKeys.BUSINESS_NAME);

        localStorage.setItem('business', JSON.stringify(res.data.business));

        this.getBusinessDetailsById(res.data.business[res.data.business.length - 1].id, type);


        this.socialLogin.logout();
      },
      (err) => {
        this.textsForScreenLoader = [];
        this.screenLoader = false;
        this._snackBar.open(
          `${err.error.message}`,
          `Close`,
          {
            duration: 1500
          }
        )
      }
    )
  }

  goToHome() {
    const favouriteBusiness = this.business.filter((d: any) => d.favourite);

    if (favouriteBusiness.length) {
      this.getBusinessDetailsById(favouriteBusiness[0].id);
    } else {
      this.getBusinessDetailsById(this.business[0].id);
    }
  }

  getBusinessDetailsById(bId: any, action?: any) {
    this._authService.getDetailsByBusinessId(bId).subscribe(
      (res: any) => {
        window.localStorage.setItem('bDetails', JSON.stringify(res.data.business));
        localStorage.setItem(StorageKeys.BUSINESS_NAME, res.data.business.name);
        this._dbService.setBusinessId(res.data.business.id);
        localStorage.setItem(StorageKeys.SUBINDUSTRYID, this.selectedIndustry.subIndustryId);
        window.localStorage.setItem('type', 'admin');
        this.getStaffPermission(localStorage.getItem(StorageKeys.STAFF_ID), bId, res.data.business.type);
        this.getCurrencySymbol(res.data.business.id)
      },
      (err) => {
        this.screenLoader = false;
        this._snackBar.open(
          `${err.error.message}`,
          `Close`,
          {
            duration: 1500
          }
        )
      }
    )
  }

  getStaffPermission(staffId: any, businessId: any, businessType: any){
    this.staffService.getStaffPermissionByStaffId(staffId, businessId).subscribe((res: any) => {
      localStorage.setItem(StorageKeys.PERMISSIONS, JSON.stringify(res.data));
      this.screenLoader = false;
      this.subscriptionService.changeCurrentPlanDetails();
      if(!businessType || businessType == 'STATIC')
        this.router.navigate(['/admin/home']);
      else
        this.router.navigate(['/admin/products/list-products']);
    },(err) => {
      this.screenLoader = false;
      this._snackBar.open(
        `${err.error.message}`,
        `Close`,
        {
          duration: 1500
        }
      )
    })
  }


  getCurrencySymbol(bId: any) {
    this._businessService.getCurrencySymbol(bId).subscribe(
      (res: any) => {
        console.log(res.data);
        this._dbService.setBusinessPrefernce(JSON.stringify(res.data));
      },
      (err) => {
        console.log(err)
      }
    )
  }

  shareWebsiteMobile() {
    // this._websiteService.openBottomSheet(ShareWebsiteComponent, {
    //   webId:
    //     window.location.href +
    //     `?tempId=${localStorage.getItem(StorageKeys.WEB_CODE)}`,
    // })
  }

  deletePage() {
    document.getElementById('closeaddPageBottomSheet')?.click()
    if (this.staffId) {
      this.createBusiness('NOT_PUBLISHED');
    } else {
      if (this.srcWidth > 475) {
        this.openingSignupPopup()
      }
      if (this.srcWidth < 475) {
        let bottom = this.templateService.openBottomSheet(
          SingupPopupComponent,
          '',
          '',
        )
      }
    }
  }

  openEditor() {
    document.getElementById('closeaddPageBottomSheet')?.click()
    if (this.staffId) {
      this.createBusiness('NOT_PUBLISHED');
    } else {
      if (this.srcWidth > 475) {
        this.openingSignupPopup()
      }
      if (this.srcWidth < 475) {
        let bottom = this.templateService.openBottomSheet(
          SingupPopupComponent,
          '',
          '',
        )
      }
    }
  }

  changeSiteStyle() {
    if (this.staffId || this.googleTokenId) {
      this.createBusiness('NOT_PUBLISHED', 'openSiteStyle')
    } else {
      if (this.srcWidth > 475) {
        this.openingSignupPopup()
      }
      if (this.srcWidth < 475) {
        let bottom = this.templateService.openBottomSheet(
          SingupPopupComponent,
          '',
          '',
        )
      }
    }
  }

  redirectToPage() {

    this.templatePages.forEach((page: any, index: number) => {
      if (page.id === this.currentPageId) {
        this.currentPageIndex = index;
      }
    })

    let section: Section[] = [];
    if (this.templatePages[this.currentPageIndex].pageName === 'Home') {
      section = this.templatePages[this.currentPageIndex].components;
    } else {
      this.addSectionForOtherPage(section)
    }

    Promise.all(this.getComponentPromise(section)).then(() => {
      const instance = this._webisteGenerationService.createDynamicComponents(this.websiteStructure, section, true);
      this.initiateSubscription(instance);
    })
  }

  changePage() {
    if (this.staffId || this.googleTokenId) {
      this.redirectToPage();
    } else {
      this.regenerateButtonCountClick = this.regenerateButtonCountClick + 1;
      if (this.regenerateButtonCountClick <= 5) {
        this.redirectToPage();
      } else {
        this.openingSignupPopup();
      }
    }
  }

  getComponentPromise(components: any[]) {
    let data = {
      subIndustryId: this.selectedIndustry?.subIndustryId
    }
    const promises = components.map(async (section, index) => {
        if(section.type === 'E_COMMERCE') {
          data['sectionType'] = section.sectionType;
          data['collectionId'] = section?.content?.collectionId;
          data['categoryId'] = '';
          data['productId'] = '';
          section['responseData'] = await this.ecomService.getData(data);
        }
    });

    return promises;
  }


  addSectionForOtherPage(section: Section[]) {
    section.push(this.templatePages[0].components[0]);
    for (let sec of this.templatePages[this.currentPageIndex].components) {
      section.push(sec);
    }
    section.push(this.templatePages[0].components[this.templatePages[0].components.length - 1]);
  }

  createNewPage(){
    if (this.staffId || this.googleTokenId) {
      this._snackBar.open('Please publish to add pages', 'close', {duration: 1500})
    } else {
      this.openingSignupPopup();
    }
  }

  regenerateSection: boolean = false;
  openRegeneratePopup() {
    this.regenerateSection = !this.regenerateSection;
  }
  stopPropagation(ev: MouseEvent) {
    if (ev) {
      ev.stopPropagation();
    }
  }
  // Listen for clicks on the document to close popup when clicked outside
  @HostListener('document:click', ['$event'])
  onClick(event: MouseEvent) {
    if (!this.containerRef.nativeElement.contains(event.target)) {
      this.regenerateSection = false;
    }
  }

  entireWebsiteRegenerate() {
    if (this.subscriptions) {
      this.subscriptions.forEach((item, j) => {
        item.unsubscribe();
      });
    }
    {
      this.screenLoader = true;
      localStorage.setItem(StorageKeys.BUSINESS_NAME, this.bName);
      localStorage.removeItem('templateId');
      this.globalRegenerateComponent = false;
      this.websiteStructure.clear();
      this.generateWebsite();
    }
    this.regenerateButtonCountClick = this.regenerateButtonCountClick + 1
    if (this.regenerateButtonCountClick > 5) {
      if (this.srcWidth > 475) {
        this.openingSignupPopup()
      }
      if (this.srcWidth < 475) {
        let bottom = this.templateService.openBottomSheet(
          SingupPopupComponent,
          '',
          '',
        )
      }
      this.screenLoader = false;
      this.globalRegenerateComponent = false;
    }
  }

  colorPalletBoolean: boolean = false;
  openColor() {
    this.colorPalletBoolean = true
    this.regenerateSection = false;
    this.regenerateFontsBoolean = false;
  }
  closePalette() {
    this.colorPalletBoolean = false;
    this.regenerateSection = true;
  }
  regenerateFontsBoolean: boolean = false;
  openFonts() {
    this.regenerateFontsBoolean = true
    this.regenerateSection = false
    this.colorPalletBoolean = false
  }
  closeFonts() {
    this.regenerateFontsBoolean = false;
  }
  themes: any[] = [];
  getAllThemes() {
    this.templateService.getAllThemePallet().subscribe((response: any) => {
      if (response)
        this.themes = response.data;
    }, (error) => {

    })
  }

  selectedTheme: any;

  selectTheme(theme: any) {
    this.selectedTheme = theme;
    console.log(this.selectedTheme)
    this.websiteEditorService.applyGlobalThemeColor(theme, this.templatePages)
  }

  applyFontFamily(){
    if(this.templatePages[0]?.fonts && this.templatePages[0]?.fonts[0]){
      Object.keys(this.templatePages[0].fonts[0]).forEach((key, index) => {
        const value = this.templatePages[0].fonts[0][key];
        document.documentElement.style.setProperty('--bs-head-font-family', key);
        document.documentElement.style.setProperty('--bs-head-font-weight', value);
      });
    }
    if(this.templatePages[0]?.fonts && this.templatePages[0]?.fonts[1]){
      Object.keys(this.templatePages[0].fonts[1]).forEach((key, index) => {
        const value = this.templatePages[0].fonts[0][key];
        document.documentElement.style.setProperty('--bs-body-font-family', key);
        document.documentElement.style.setProperty('--bs-body-font-weight', value);
      })
    }
  }

  selectedFontFamily(item) {
    // console.log(item)
    if (item.headingFontValues && typeof item.headingFontValues.fontFamily === 'string') {
      // Set the custom property on the document root
      document.documentElement.style.setProperty('--bs-head-font-family', item.headingFontValues.fontFamily);
    }
    if (item.headingFontValues && typeof item.headingFontValues.fontWeight === 'string') {
      document.documentElement.style.setProperty('--bs-head-font-weight', item.headingFontValues.fontWeight);
    }
    if (item.bodyFontValues && typeof item.bodyFontValues.fontFamily === 'string') {
      document.documentElement.style.setProperty('--bs-body-font-family', item.bodyFontValues.fontFamily)
    }
    if (item.bodyFontValues && typeof item.bodyFontValues.fontWeight === 'string') {
      document.documentElement.style.setProperty('--bs-body-font-weight', item.bodyFontValues.fontWeight);
    }
    // document.documentElement.style.setProperty('--bs-head-font-family', item.headingFontValues.fontFamily);
  }
}
