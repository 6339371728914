import { Component, HostListener, Inject, Optional } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatBottomSheetRef, MAT_BOTTOM_SHEET_DATA } from '@angular/material/bottom-sheet';
import { BlogServiceService } from '../blog-service.service';
import { Router } from '@angular/router';
import { EventEmmiterService } from 'src/app/services/event-emmiter.service';

@Component({
  selector: 'app-confirmation-filter',
  templateUrl: './confirmation-filter.component.html',
  styleUrls: ['./confirmation-filter.component.scss']
})
export class ConfirmationFilterComponent {

  constructor(
    private dialogRef: MatDialogRef<ConfirmationFilterComponent>,
    private _bottomSheetRef: MatBottomSheetRef<ConfirmationFilterComponent>,
    @Optional()   @Inject(MAT_DIALOG_DATA) public data,
    @Optional() @Inject(MAT_BOTTOM_SHEET_DATA) public bottomSheetData: any,
    private blogService:BlogServiceService,
    private router: Router
  ){
    this.getScreenSize()
  }

  screenWidth: any;

  @HostListener("window:resize",["$event"])
  getScreenSize() {
    this.screenWidth = window.innerWidth;
  }

  isLoading: boolean = false;
  back() {
    // if (this.isLoading) return;
    this.router.navigate(['/admin/website/blogs/listing']);
    if (this.screenWidth > 475){  
      this.dialogRef.close({ended:"closed"})
    }

    if (this.screenWidth <= 475){
      this._bottomSheetRef.dismiss({ended:"closed"})
    }
  }

  cancel(){
    if (this.isLoading) return;

    if(this.screenWidth > 475){
      this.dialogRef.close({ended:"closed"})
    }
    if (this.screenWidth < 475){
      this._bottomSheetRef.dismiss({ended:"closed"})
    }
  }
}

