import { Component, Inject, Optional, HostListener } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Field } from 'simpo-component-library/lib/sections/contact-us/contact-us.modal';
import { MAT_BOTTOM_SHEET_DATA, MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { INPUT_FIELDS_TYPE } from 'simpo-component-library';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { CrmServiceService } from 'src/app/master-config-components/micro-apps/crm/service/crm-service.service';
import { FormControl, Validators } from '@angular/forms';
import { CreateFieldGroupComponent } from 'src/app/master-config-components/micro-apps/crm/crm-config/template/create-field-group/create-field-group.component';
import { DbService } from 'src/app/services/db-service/db.service';
interface FIELD_INFO {
  icon: string,
  text: string,
  description: string,
  customIcon: boolean,
  iconTxt: string | null,
  type: INPUT_FIELDS_TYPE
}

@Component({
  selector: 'app-add-contact-fields',
  templateUrl: './add-contact-fields.component.html',
  styleUrls: ['./add-contact-fields.component.scss'],
})
export class AddContactFieldsComponent {
  constructor(
    public dialogRef: MatDialogRef<AddContactFieldsComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
    public bottomSheetRef: MatBottomSheetRef<AddContactFieldsComponent>,
    @Optional() @Inject(MAT_BOTTOM_SHEET_DATA) public bottomData: any,
    private crmService: CrmServiceService,
    private dbService: DbService,
    public dialog: MatDialog
  ) {
    this.getFieldGroupList();
    this.getDataSourceTypesApi();
    this.getScreenSize();
    this.checkLimits()
    this.getAllFieldTypes();
  }

  showWarning = false;
  fieldCategory: any;

  checkLimits() {
    if (this.payload.minLimit > 0) {
      this.showWarning = this.payload.minLimit !== null && this.payload.maxLimit !== null && this.payload.minLimit >= this.payload.maxLimit;
    }
  }

  fieldTypeList: any[] = [];
  getAllFieldTypes() {
    this.crmService.getFieldType().subscribe((res: any) => {
      this.fieldTypeList = res.data;
    })
  }

  searchField: any = '';
  availableFields = [
    { icon: 'text', text: 'text', description: 'Type in a single line of text like a name or note', type: "text", customIcon: true, iconTxt: 'A|' },
    { icon: 'number', text: 'number', description: 'Type in a number without decimals or fractions', type: "number", customIcon: true, iconTxt: '#|' },
    { icon: 'money', text: 'money', description: 'Type in a monetary value, with currency', type: "money", customIcon: true, iconTxt: '₹|' },
    { icon: 'calendar_today', text: 'date', description: 'select a day of the year from the  calender', type: "date", customIcon: false, iconTxt: null },
    { icon: 'check_circle_outline', text: 'checkbox', description: 'One or more yes or no choices', type: "checkbox", customIcon: false, iconTxt: null },
    { icon: 'format_list_bulleted', text: 'dropdown', description: 'Select a single choice from a menu of options you define', type: "dropdown", customIcon: false, iconTxt: null },
    { icon: 'star_border', text: 'rating', description: 'An input for a rating from 1 to 5 starts', type: "rating", customIcon: false, iconTxt: null },
    { icon: 'radio_button_checked', text: 'Radio Button', description: 'One or more yes or no choices', type: "radioButton", customIcon: false, iconTxt: null },
  ];

  screen: "SELECT_FIELDS" | "ADD_FIELDS" | "EDIT_FIELD" = "SELECT_FIELDS";
  public selectedInputType: INPUT_FIELDS_TYPE | null = "text";
  public newFieldLabel: string = "";
  addedField: Field[] = [];
  customAddedFields: any[] = [];

  fieldOptions: { label: string, value: string }[] = [{ label: '', value: '' }];
  optionsCount: number = 1;
  // fieldsdata: any;
  payload: any = {
    fieldId: this.data.data.fieldId,
    businessId: this.dbService.getBusinessId(),
    fieldLabel: this.data.data.fieldLabel,
    dataType: this.selectedInputType,
    showInEnquiry: this.data.data.showInEnquiry ?? false,
    showInRegistration: this.data.data.showInRegistration,
    showInAdmission: this.data.data.showInAdmission,
    hint: this.data.data.hint,
    errorMessage: this.data.data.errorMessage,
    minLimit: this.data.data.minLimit,
    maxLimit: this.data.data.maxLimit,
    status: true,
    dataFieldConstraints: [],
    selected: true,
    default: true,
    fieldGroup: [],
    required: this.data.data.required,
    filterable: this.data.data.filterable,
    searchable: this.data.data.searchable,
    dataSourceType: null,
    value: null,
    type: null,
    multipleSelection: false
  }

  srcHeight: any;
  screenWidth: any;

  @HostListener('window:resize', ['$event'])
  getScreenSize(event?: any) {
    this.srcHeight = window.innerHeight;
    this.screenWidth = window.innerWidth;
  }

  selectedfieldgroupId: any;

  ngOnInit() {
    if (this.data.edit === true) {
      this.selectedFieldGroups = this.data.data.fieldGroup

      //;
      this.payload = this.data.data;
      this.selectedInputType = this.data.selectedInputType.toLowerCase();
      this.payload.fieldGroup = this.data.data.fieldGroup || [];
      // this.selectedFieldGroups = this.payload.fieldgroup.map((group)=> {
      //   console.log(this.selectedFieldGroups)
      //   return this.fieldGroups.find((fg)=> fg.fieldGroupId === group.fieldGroupId);
      // });
      this.payload.dataFieldConstraints = this.data.data.dataFieldConstraints || [];
      this.selectedValidations = this.payload.dataFieldConstraints.map((constraint) => {
        return this.validationList.find((validation) =>
          validation.id === constraint.id);
      });
    }
    this.fieldCategory = this.data?.fieldCategory ? this.data.fieldCategory : "ENQUIRY";
    this.screen = this.data.screen
    if (this.data?.data) {
      this.addedField = this.data.data;
    }
    if (this.bottomData?.data) {
      this.addedField = this.bottomData.data;
    }
    this.addCustomFields();
    this.screenSize();
    this.getFieldsApi();
  }

  @HostListener('window: resize', ['$event'])
  screenSize() {
    this.screenWidth = window.innerWidth
  }

  get getInputType(): string {
    return this.selectedInputType as unknown as string;
  }
  get canAddMoreOption(): boolean {
    return this.fieldOptions.every((field) => field.value?.length > 0);
  }

  pageLoader: boolean = false;
  pageNo: number = 0;
  size: number = 10;
  status = true;
  showfields: any;
  responseData: any = [];
  dataSource: any = []
  fieldGroups: any = []

  selectedFieldGroups: any = [];
  tempCreatedGroup: any;

  opencreategroup() {
    let dialog = this.dialog.open(CreateFieldGroupComponent, {
      height: '100vh',
      width: '35%',
      position: { top: '0', right: '0' }
    })

    dialog.afterClosed().subscribe((res) => {
      if (res != null && res.data != null) {
        this.tempCreatedGroup = res.data;
        this.getFieldGroupList();
      }
    });
  }

  selectedValidations: { id: number; name: string }[] = [];
  updateDataFieldConstraints(): void {
    this.payload.dataFieldConstraints = [];

    this.selectedValidations.forEach((validation: { id: number; name: string }) => {
      // this.payload.dataFieldConstraints[validation.id] = validation.name;

      this.payload.dataFieldConstraints.push({
        id: validation.id,
        name: validation.name
      })
    });

    if (this.data.edit == true) {
      this.payload.dataFieldConstraints = this.data.data.dataFieldConstraints
    }

  }

  // updateFieldGroups() {
  //   // Ensure fieldgroup and fieldGroup are in sync
  //   debugger;
  //   this.payload.fieldGroup = this.selectedFieldGroups.map((group) => ({
  //     fieldGroupId: group.fieldGroupId,
  //     fieldGroupName: group.fieldGroupName,
  //   }));
  // }
  selected = new FormControl('valid', [Validators.required, Validators.pattern('valid')]);

  selectFormControl = new FormControl('valid', [Validators.required, Validators.pattern('valid')]);

  nativeSelectFormControl = new FormControl('valid', [
    Validators.required,
    Validators.pattern('valid'),
  ]);

  validationList = [{ "id": 1, "name": "Uppercase" }, { "id": 2, "name": "Lowercase" }, { "id": 3, "name": "Numbers(1, 2, ..)" }, { "id": 4, "name": "Special characters (@,#,..)" }];


  selectedSourceOption: string = 'Masters'; // Default value
  dataSourceTypes: string[] = []; // Dynamic list of strings
  selectedSourceItem: string = '';

  onSelectionChange(event: any): void {
    this.selectedSourceOption = event.value;
    // Additional logic can go here
  }

  getFieldsApi() {
    this.pageLoader = true;
    this.payload.dataFieldConstraints = [];
    this.crmService.getAllCustomFields(this.dbService.getBusinessId(), '').subscribe(
      {
        next: (value: any) => {
          this.responseData = value.data.data
          this.dataSource = value.data.data
          this.pageLoader = false;
        },
        error: (err: any) => {
          this.crmService.openSnack(err.error.message, "Close");
        },
      }
    )

  }

  getDataSourceTypesApi() {
    this.crmService.getDataSourceTypesApi().subscribe(
      {
        next: (value: any) => {
          this.dataSourceTypes = this.removeUnderscores(value.data);
        },
        error: (err: any) => {
          this.crmService.openSnack(err.error.message, "Close");
        },
      }
    )

  }
  removeUnderscores(list: string[]): string[] {
    return list.map(item => item.replace(/_/g, ' '));
  }

  private addCustomFields() {
    this.customAddedFields = this.addedField.filter((field) => Object.hasOwn(field, 'status'));
  }

  closeModal() {
    if (this.screenWidth > 475) {
      this.dialogRef.close()
    }
    if (this.screenWidth < 475) {
      this.bottomSheetRef.dismiss()
    }
  }

  changeTextField(field: any, type: 'CHECK' | 'REQUIRED') {
    if (type == 'REQUIRED')
      field.status = true;
    let index = -1;
    for (let i = 0; i < this.addedField.length; i++) {
      if (this.addedField[i].label === field.label) {
        this.addedField[i].required = (field.required && type == 'REQUIRED');
        index = i;
        break;
      }
    }

    if (index == -1) {

      this.addedField.push({
        label: field.label,
        value: field.value,
        type: field.type,
        required: field.required,
        options: field.options,
      })
    } else if (type == 'CHECK') {

      if (Object.hasOwn(field, "status"))
        field.status = false
      else
        this.addedField.splice(index, 1);
    }
  }

  public addNewInput(field) {
    this.selectedInputType = field.type;
    this.screen = "EDIT_FIELD";
  }
  public cancelEdit() {
    this.screen = "ADD_FIELDS";
    this.newFieldLabel = ""; 1
  }
  public cancelfromlistedit() {
    this.dialogRef.close()
  }
  isFiledOptionEmpty: boolean = false;
  public saveEdit() {
    if(window.location.pathname.includes('list-fields')){
      this.payload.showInEnquiry = this.payload.showInEnquiry ?? false;
      this.addCustomFieldToList();
    }
    else{
      this.payload.showInEnquiry = true;
      this.addCustomFieldToList();
    }
  }


  addCustomFieldToList(){
    if (!this.isFormValid()) {
      this.crmService.openSnack("Field Label is required.", "Close");
      return;
    }
    this.payload.fieldGroup = [];
    if (this.fieldGroups != null && this.selectedFieldGroups != null) {
      this.fieldGroups.forEach(group => {
        this.selectedFieldGroups.forEach(selectedFG => {
          if (selectedFG.fieldGroupId === group.fieldGroupId) {
            this.payload.fieldGroup.push({
              fieldGroupId: group.fieldGroupId,
              fieldGroupName: group.fieldGroupName,
              fieldStepId: group.fieldParentGroup ? group.fieldParentGroup.id : null,
              fieldStepName: group.fieldParentGroup ? group.fieldParentGroup.name : null,

            })
          }
        });
      });
    }

    if ((this.selectedInputType == "checkbox" || this.selectedInputType == "dropdown") && this.selectedSourceOption === "Masters") {
      if (this.selectedSourceItem == null || this.selectedSourceItem == "") {
        this.crmService.openSnack("Please Select Source Item", "Close");
        return;
      }
    }


    
    this.payload.dataType = this.selectedInputType?.toUpperCase() as INPUT_FIELDS_TYPE;
    if ((this.selectedInputType == "checkbox" || this.selectedInputType == "dropdown") && this.selectedSourceOption === "Masters") {
      this.payload.dataSourceType = (this.selectedSourceItem.split(' ').join('_')).toUpperCase();
    }

    if (this.fieldOptions != null && this.fieldOptions.length > 0 && this.selectedSourceOption === "Custom Options") {
      this.isFiledOptionEmpty = this.fieldOptions[0].label === ""; // Check the first field option

      if (!this.isFiledOptionEmpty) {
        this.payload.value = this.fieldOptions; // Only set payload if the first label is not empty
      }
    }
    if (this.data.edit === true) {
      this.crmService.updatefields(this.payload, this.fieldCategory).subscribe((res: any) => {
        this.payload = res.data;
        this.payload.showInEnquiry = res.data.showInEnquiry;
        this.payload.showInRegistration = res.data.showInRegistration;
        this.payload.showInAdmission = res.data.showInAdmission;
        this.payload.fieldGroup = this.selectedFieldGroups
        //;
        this.crmService.openSnack("Field updated successfully", "Close");
      })
    } else {
      this.crmService.createfields(this.payload, this.fieldCategory).subscribe(
        (res: any) => {
          if(window.location.pathname.includes('list-fields')){
            this.crmService.openSnack("Field created successfully", "Close");
            this.getFieldsApi();
          }
          if(window.location.pathname.includes('enquiry-form')){
            this.crmService.createFieldInForms.emit({createFrom:"ENQUIRY_FORM"});
          }
          if(window.location.pathname.includes('website-editor')){
            this.crmService.createFieldInForms.emit({createFrom:'WEBSITE_EDITOR'})
          }

          this.payload = res.data;
        },
        (err) => {
          this.crmService.openSnack(
            err.error.message,
            'Close'
          )
        }
      )

    }

    if (this.screen == "EDIT_FIELD") {
      this.dialogRef.close();
    } else {
      if (!this.selectedInputType)
        return;
      this.addedField.push({
        label: this.newFieldLabel = this.payload.fieldLabel,
        value: this.newFieldLabel,
        type: this.selectedInputType,
        required: false,
        status: true,
        options: (this.selectedInputType == "checkbox" || this.selectedInputType == "dropdown") ? this.fieldOptions.filter((option) => option.value.length > 0) : [],
      });
      this.customAddedFields.push({
        label: this.newFieldLabel,
        value: this.newFieldLabel,
        type: this.selectedInputType,
        required: false,
        options: (this.selectedInputType == "checkbox" || this.selectedInputType == "dropdown") ? this.fieldOptions.filter((option) => option.value.length > 0) : [],
        status: true,
      });
      this.newFieldLabel = "";
      this.screen = "SELECT_FIELDS";
    }

  }

  prepareFieldGroupData() {
    //;
    this.payload.fieldGroup = [];
    if (this.fieldGroups != null && this.selectedFieldGroups != null) {
      this.fieldGroups.forEach(fieldGroup => {
        if (this.selectedFieldGroups.includes(fieldGroup.fieldGroupId)) {
          this.payload.fieldGroup.push({
            id: fieldGroup.fieldGroupId,
            name: fieldGroup.fieldGroupName
          })
        }
      });
    }
  }


  getFieldGroupList() {
    this.pageLoader = true;
    this.crmService.getFieldGroupApi(this.dbService.getBusinessId()).subscribe(
      {
        next: (value: any) => {
          this.fieldGroups = value.data.data;
          this.pageLoader = false;
          // //;
          if (this.tempCreatedGroup != null) {
            this.fieldGroups.forEach(element => {
              if (this.tempCreatedGroup.fieldGroupId === element.fieldGroupId) {
                this.selectedFieldGroups = element
              }
            });
          } else if (this.data.edit === true) {
            if (this.fieldGroups != null && this.fieldGroups.length > 0) {
              this.fieldGroups.forEach(element => {
                if (this.payload.fieldGroup != null && this.payload.fieldGroup.length > 0) {
                  this.payload.fieldGroup.forEach(fieldGroup => {
                    if (fieldGroup.fieldGroupId === element.fieldGroupId) {
                      this.selectedFieldGroups.push(element);
                    }
                  });
                }

              });
            }
          }
        },
        error: (err: any) => {
          this.crmService.openSnack(err.error.message, "Close");
        },
      }
    )
  }

  addMoreOption() {
    if (!this.canAddMoreOption)
      return;
    this.fieldOptions.push({ label: '', value: '' });
    this.optionsCount++;
  }
  editInput(field) {
    this.screen = "EDIT_FIELD";
    this.selectedInputType = field.type;
    this.newFieldLabel = field.value;
    this.optionsCount = field.options.length;
    this.fieldOptions = field.options;
  }
  deleteInput(field) {
    this.addedField = this.addedField.filter((f) => f.value != field.value);
    this.customAddedFields = this.customAddedFields.filter((f) => field.value != f.value);
  }
  removeOption(idx: number) {
    if (this.optionsCount == 1)
      return;
    this.fieldOptions = this.fieldOptions.filter((_, index: number) => idx != index);
    this.optionsCount--;
  }
  drop(event: CdkDragDrop<unknown>) {
    moveItemInArray(this.customAddedFields, event.previousIndex, event.currentIndex);
  }
  isValueConflict(idx: number) {
    return this.fieldOptions.filter((option, index) => option.value == this.fieldOptions[idx].value && idx != index).length > 0;
  }

  showInEnquiry: boolean = false;

  onCheckboxChange() {
  }


  showEditor: boolean = false;
  multiTabEditor: boolean = false;

  dismiss() {
    this.showEditor = false;
    this.multiTabEditor = false;

    this.dialogRef.close();

  }

  isFormValid(): boolean {
    return !!this.payload.fieldLabel;
  }

  searchListField() {
    this.crmService.getAllCustomFields(this.dbService.getBusinessId(), this.searchField).subscribe(
      (res: any) => {
        this.responseData = res.data.data
        this.dataSource = res.data.data
      },
      (err) => {
        this.crmService.openSnack(
          `${err.error.message}`,
          'Close'
        )
      }
    )
  }

  updateItem(event: any, item: any) {
    this.crmService.addOrRemoveItemFromWebForm.emit({ updateItem: true, status: event.checked, value: item });
  }

  upadteIsRequired(ev: any, item: any) {
    this.crmService.updateIsRequiredInWebForm.emit({ isRequered: true, status: ev.checked, value: item })
  }

}