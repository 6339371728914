<section class="main_section">
    <div class="title">
        Connect existing domain
        <div><mat-icon (click)="close()">close</mat-icon></div>
    </div>
    <div class="hr-line"></div>
    <div class="registerDomain">
        Registered Domain
    </div>
    <div class="form_field business-field-section mb-15">
        <mat-form-field class="sfull-width" appearance="outline">
            <input placeholder="https://beeos.com" matInput [(ngModel)]="linkingDomain" pattern="[a-z.]+" />
        </mat-form-field>
    </div>
    <div class="middle-container">
        <p class="f-14 l-24 fw-400 color-black">Follow below steps to map your existing domain.</p>
        <div class="steps-container">
            <div class="step mb-15" *ngFor="let ele of steps">
                <div class="sub-head f-14 l-24 fw-600">{{ele.subhead}}</div>
                <div class="text f-14 fw-400 l-24">{{ele.text}}</div>
                <div class="sublist-container" *ngIf="ele.subList.length > 0">
                    <div class="sublist d-flex g-8" *ngFor="let i of ele.subList">
                        &#x2022;<div class="sub-head f-14 l-24 fw-600">{{i.sublistHead}}</div>
                        <div class="sublist-text f-14 l-24 fw-600 color-blue">{{i.subListText}}
                            <img src="../../../../../assets/images/master/copy.svg" alt=""
                                class="cursor-pointer focus-ring ms-1" (click)="copyToClipboard(i.subListText)" *ngIf="i.subListText != 'A'">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="btnRightAlign">
        <button class="buttonStyle" [disabled]="linkingDomain.length == 0 || ct < 2">Submit & Verify</button>
        <button class="buttonStyle" *ngIf="submitLoader">
            <i class="fa fa-spinner fa-spin"></i> &nbsp;Loading..
        </button>
    </div>
</section>