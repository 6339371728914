import { Component, ViewChild, ViewContainerRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { EventsService } from 'simpo-component-library';
import { CrmServiceService } from 'src/app/master-config-components/micro-apps/crm/service/crm-service.service';
import { DbService } from 'src/app/services/db-service/db.service';
import { WebsiteGenerationService } from 'src/app/website-generation/website-generation.service';
import { cashfreeSandbox, cashfreeProd } from "cashfree-pg-sdk-javascript";
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-view-registration-form',
  templateUrl: './view-registration-form.component.html',
  styleUrls: ['./view-registration-form.component.scss']
})
export class ViewRegistrationFormComponent {
  constructor(
    private crmservice: CrmServiceService,
    private websiteGenerationService: WebsiteGenerationService,
    private _dbService: DbService,
    private router: ActivatedRoute,
    private _eventService: EventsService
  ) {}

  @ViewChild('displayWebsite', { read: ViewContainerRef })
  displayWebsite: ViewContainerRef | any;
  // businessDetails : any ;
  registrationFormComponent: any[] = []

  templateId: string = '';
  businessId: string = '';

  ngOnInit() {
    // this.businessDetails = JSON.parse(window.localStorage.getItem('bDetails') || '');
    this.router.queryParams.subscribe((param: any) => {
      this.templateId = param['tId'];
      this.businessId = param['bId'];
    })
    this.getRegistrationForm();
    this.loadCashfreeSdk();

  }

  getRegistrationForm() {
    this.crmservice.getWebsiteTemplate(this.templateId).subscribe((res: any) => {
        for(let component of res?.data[0]?.components) {
          if(component.sectionType.toLowerCase() === 'header') {
            this.registrationFormComponent[0] = component;
          }
          if(component.sectionType.toLowerCase() === 'registrationform') {
            this.registrationFormComponent[1] = component;
          }
          if(component.sectionType.toLowerCase() === 'footer') {
            this.registrationFormComponent[2] = component;
          }
        }
        this.displayForm();
    })
  }

  displayForm(){
    setTimeout(() => {
      const instance = this.websiteGenerationService.createDynamicComponents(this.displayWebsite, this.registrationFormComponent, false);
    }, 500);
  }

  cashFreeEvent?: Subscription;
  loadCashfreeSdk() {
    this.cashFreeEvent = this._eventService.cashFreeEvent.subscribe((res: any) => {
      const paymentSessionId = res.response.data.sessionId;
      localStorage.setItem("registrationOrderId", res.response.data.orderId);

      let cashFree;
      // if (environment.production) {
      //   cashFree = new cashfreeProd.Cashfree(paymentSessionId);
      // } else {
        cashFree = new cashfreeSandbox.Cashfree(paymentSessionId);
      // }
      cashFree.redirect();
    })
  }

}
